import "core-js/stable";
import 'core-js/stable/promise/finally'

function usagePolyfill() {
  new Set()
  new Map()
  Object.entries({})
  Object.values({})
  Object.keys({})
  Object.assign({},{})
  Array.from([])
  ([]).findIndex(()=>{})
  Symbol.iterator
}
try {
  Object.getPrototypeOf("")
} catch (e) {
  const oldPropertyOf = Object.getPrototypeOf
  Object.getPrototypeOf = function(obj) { return oldPropertyOf(Object(obj)) }
}
if (!window.history.pushState) {
  window.history.pushState = function(obj,str,href){
    if (href && window.location.href !== href) {
      window.location.href = href
    }
  }
}
(function(filePrototype) {
  if (!filePrototype.slice) {
    filePrototype.slice = File.prototype.mozSlice || File.prototype.webkitSlice
  }
})(File.prototype)

// fetch() polyfill for making API calls.
require('whatwg-fetch');
require('intersection-observer');
require('url-search-params-polyfill');

// remove
(function (anchor) {
  if (!anchor.click) {
    anchor.click = function click() {
      this.dispatchEvent(new CustomEvent("click"));
    }
  }
})(HTMLAnchorElement.prototype);


// remove
(function (arr) {
  arr.forEach(function (item) {
    if (!item.remove) {
      item.remove = function remove() {
        this.parentNode.removeChild(this);
      }
    }
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);


// scroll
(function (arr) {
  arr.forEach(function (item) {
    if (!item.scroll) {
      item.scroll = function scroll(left,top) {
        this.scrollTop = top
        this.scrollLeft = left
      }
    }
  });
})([Element.prototype]);

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('append')) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

// matches closest
(function (ElementProto) {
  if (typeof ElementProto.matches !== 'function') {
    ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
        var element = this;
        var elements = (element.parentNode || element.document || element.ownerDocument).querySelectorAll(selector);
        var index = 0;

        while (elements[index] && elements[index] !== element) {
          ++index;
        }

        return Boolean(elements[index]);
      };
  }

  if (typeof ElementProto.closest !== 'function') {
    ElementProto.closest = function closest(selector) {
      var element = this;

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }

        element = element.parentNode;
      }

      return null;
    };
  }
})(Element.prototype);

(function () {

  if ( typeof window.CustomEvent === "function"  || !window.Event) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

(function() {
  var lastTime = 0;
  var vendors = ['ms', 'moz', 'webkit', 'o'];
  for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
    window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
      || window[vendors[x]+'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function(callback, element) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function() { callback(currTime + timeToCall); },
        timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function(id) {
      clearTimeout(id);
    };
}());