import React from 'react'

export const AllocationSuccess = ({values,goBack}) => {
  React.useEffect(() => {
    window.history.pushState('reload', '')
    window.addEventListener("popstate",goBack)
    return () => window.removeEventListener("popstate",goBack)
  },[])


  return <div>
    <h3>{values.pacsdbname} saved successfully</h3>
  </div>

}