import React from 'react'
import styled, {css} from 'styled-components/macro'
import _ from 'lodash/fp'
import {
  Combobox,
  Errors,
  FormValue,
  IfFormValue,
  TextInput,
  ToggleCheckbox,
  validation,
  WithForm
} from '@startlibs/form'
import {Button, SplitColumnsContainer, Icon, FlipList} from '@startlibs/components'
import {postFetcher, getUID, getColor, formatDate} from '@startlibs/utils'
import {LogoUploader} from './LogoUploader'
import {TransitionDiv} from '../../components/TransitionDiv'
import {useLazyConstant} from "@startlibs/core";
import {Billing, formatPrice} from "./customerInfo/Billing";
import {formatDateNoUTC} from "../../utils/utils";
css

const StudyNotifierContactContainer = styled.div`
  background: ${getColor('gray240')};
  padding: 1rem 1rem 0;
  border-radius: 6px;
  position: relative;
  margin-bottom: 1rem;
  ${Icon} {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    color: white;
    background: rgb(150,150,150);
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: rgb(130,130,130);
    }
  }
`

const requiredIf = (path) => (v,k,props) => _.get(path,props) && !v && validation.required()
const checkIf = (path,check) => (v,k,props) => _.get(path,props) && check(v,k,props)

const validateAdminData = (list) => _.flatten(list.map(({fullName,email},i) => {
  if (!fullName && !email) { return [] }
  const emailError = validation.emailValidation(email)
  const fullNameError = validation.required(fullName)
  return [
    fullNameError ? ['studyNotifier.adminData.'+i+'.fullName',fullNameError] : null,
    emailError ? ['studyNotifier.adminData.'+i+'.email',emailError] : null,
  ].filter(Boolean)
}))

const preValidation = validation.buildValidation({
  'name':[validation.required],
  'contact_email':[validation.required,validation.emailValidation],
  'pacsdbname':[validation.required],
  'pacsdbhost':[validation.required],
  'ae_title':[validation.required],
  'ae_port':[validation.required],
  'default_worklist':[validation.required],
  'share.system':[validation.required],
  'share.expiration_time':[validation.required],
  'billing.customerNumber':[requiredIf('billing.enabled')],
  'billing.periodType':[requiredIf('billing.enabled')],
  'billing.billingType':[requiredIf('billing.enabled')],
  'billing.unitPrice':[requiredIf('billing.enabled')],
  'billing.startDate':[checkIf('billing.enabled',(v,path,props) => _.get('billing.periodType',props) === 'YEARLY' && (v === -1 || !_.isNumber(v)) && "Billing start date is required on yearly billing period type")],
  'billing.limit':[checkIf('billing.enabled',(v,path,props) => _.get('billing.billingType',props) && _.get('billing.billingType',props) !== 'STUDY_COUNT' && !_.isNumber(v) && "Billing limit is required on limited billing type")],
  'studyNotifier.maxAgeNotifier':[requiredIf('studyNotifier.notifierEnabled'),checkIf('studyNotifier.notifiedEnabled',v => v < 30 && "Minimum \"Max Age\" value is 30")],
  'studyNotifier.maxIdleNotifier':[requiredIf('studyNotifier.notifierEnabled'),checkIf('studyNotifier.notifiedEnabled',v => v < 60 && "Minimum \"Max Idle\" value is 60")],
  'uploader.public_url_secret':[requiredIf('uploader.use_public_url')],
},(props) => {
  if (!_.get('studyNotifier.notifierEnabled',props)) {
    return {}
  }
  const failed = validateAdminData(_.get('studyNotifier.adminData',props))
  return _.fromPairs(failed)
})
const editPreValidation = validation.buildValidation({
  'name':[validation.required],
  'default_worklist':[validation.required],
  'white_label.url':[validation.required],
  'share.system':[validation.required],
  'share.expiration_time':[validation.required],
  'uploader.public_url_secret':[requiredIf('uploader.use_public_url')],
})

const transformAdminData = (list) =>
  list.filter(({email,fullName}) => email && fullName && !validation.emailValidation(email)).map(({email,fullName}) => ({email,fullName}))

const toFloat = (d) => {
  if (_.isString(d)) {
    return parseFloat(d.replace(/,/g,''))
  }
  return d
}

const addBillingDefaults = _.flow(
  _.set('enabled',false),
  _.update('periodType',v => v || 'DAY_1'),
  _.update('billingType',v => v || 'STUDY_COUNT'),
  _.update('unitPrice',v => v || 0),
  _.update('customerNumber',v => v || ''),
  (billing) => billing?.periodType === 'YEARLY' && (billing?.startDate === -1 || !_.isNumber(billing?.startDate))
    ? _.set('startDate',formatDate(Date.now(),'yyyy-MM-dd'),billing) : billing,
  (billing) => billing?.billingType && billing?.billingType !== 'STUDY_COUNT' && !_.isNumber(billing?.limit)
    ? _.set('limit',1,billing) : billing,
)

const transform = _.flow(
  _.update('ae_port',Number),
  _.update('share.expiration_time',Number),
  _.update('studyNotifier.adminData',transformAdminData),
  _.update('studyNotifier.maxAgeNotifier',v => !v || v<30 ? 30 : v),
  _.update('studyNotifier.maxIdleNotifier',v => !v || v<60 ? 60 : v),
  _.update('uploader',values => values?.use_public_url ? values : _.unset('public_url_secret',values)),
  (values) => values.white_label?.url ? values : _.unset('logo',values),
  (values) => values.white_label?.url ? values : _.unset('white_label.sso',values),
  _.update('billing.startDate',d => d ? formatDate(d,'yyyy-MM-dd') : d),
  _.update('billing.unitPrice',d => d ? toFloat(d) : d),
  _.update('billing', billing => billing?.enabled ? billing : addBillingDefaults(billing))
)

const EXPIRATION_LABELS = {0:'Never',48:'48 hours',168:'1 week'}
const GET_EMPTY_NOTIFIER_ADMIN_DATA = () => [{email:"",fullName:"",key:getUID()}]
export const CustomerInfoForm = ({values,goBack,onSuccess}) => {
  React.useEffect(() => {
    window.history.pushState('reload', '')
    window.addEventListener("popstate",goBack)
    return () => window.removeEventListener("popstate",goBack)
  },[])

  const isNew = values.isNew

  const formattedValues = useLazyConstant(() =>
    _.flow(
      _.unset('logo'),
      _.update('studyNotifier.adminData',_.map(_.update('key',getUID))),
      _.update('studyNotifier.adminData',v => !v.length ? GET_EMPTY_NOTIFIER_ADMIN_DATA() : v),
      _.update('billing.startDate',v => v ? new Date(v).getTime() : v),
      _.update('billing.unitPrice',v => v ? formatPrice(v+"") : v)
    )(values)
  )

  return <WithForm
    values={formattedValues}
    preValidation={preValidation}
    transform={transform}
    onSuccess={onSuccess}
    onFailure={validation.responseFailure((v) => ({'':["Communication with the server has failed"]}))}
    action={(formValues) => postFetcher( `/api/customer/${values.pacsdbname}`,formValues,isNew ? {method: "POST"} : {method: "PUT"})}
  >{ form => <>

    <TextInput label="Name" path="name" mandatory />
    <TextInput label="Contact email" path="contact_email" mandatory disabled={!isNew}/>
    <TextInput label="PACS DB name" path="pacsdbname" disabled />
    <TextInput label="PACS DB host" path="pacsdbhost" mandatory disabled={!isNew}/>
    <TextInput label="AE Title" path="ae_title" mandatory disabled={!isNew}/>
    <TextInput label="AE Port" path="ae_port" constraint='number' mandatory disabled={!isNew}/>
    <Combobox
      label="Default worklist" path="default_worklist" mandatory disabled={!isNew}
      options={['home5-beta','newhome']}
    />
    <ToggleCheckbox label="Viva lite" path="viva_lite" />


    <div>
      <h3>White label</h3>
      <TextInput label="URL" path="white_label.url" disabled={!isNew}/>
      <IfFormValue block path="white_label.url">
        <ToggleCheckbox label="Single SignOn" path="white_label.sso"/>
        <LogoUploader form={form} originalPicture={values.logo}/>
      </IfFormValue>
    </div>

    <div>
      <h3>Report</h3>
      <ToggleCheckbox label="Report Multitenant" path="report_multitenant"/>
    </div>

    <div>
      <h3>Uploader</h3>
      <ToggleCheckbox label="Use public URL" path="uploader.use_public_url" />
      <IfFormValue path="uploader.use_public_url" block>
        <TextInput path="uploader.public_url_secret" label="Public URL Secret" mandatory/>
      </IfFormValue>
    </div>

    <div>
      <h3>PAS</h3>
      <ToggleCheckbox label="Use 2FA" path="pas.using_2fa" />
      <ToggleCheckbox label="Confirmation email mandatory" path="pas.confirmation_email_mandatory" />
    </div>

    <div>
      <h3>Share</h3>
      <TextInput label="System" path="share.system" mandatory disabled/>
      <TextInput label="Custom message" path="share.custom_message" />
      <ToggleCheckbox label="Send securely" path="share.send_securely" />
      <Combobox label="Expiration time" path="share.expiration_time" mandatory
        options={['0','48','168']}
        getLabel={(v) => EXPIRATION_LABELS[v] || ""}
      />
      <div>
        <FormValue path="share.include_study_report">{(value,setValue) => <>
          <ToggleCheckbox label="Enable Study Report" raw value={!!value} setValue={() => setValue(v => v ? 0 : 1)} />
          <TransitionDiv block>{
            value && <ToggleCheckbox css="margin-top: -0.5rem;" label="Include Study Report" raw value={value === 2} setValue={() => setValue(v => v === 1 ? 2 : 1)} />
          }</TransitionDiv>
        </>}</FormValue>

        <ToggleCheckbox label="Allow image download" path="share.allow_image_download" />
        <ToggleCheckbox label="PHI protection" path="share.phi_protection" />
        <ToggleCheckbox label="PHI protection DOB" path="share.phi_protection_dob" />
        <ToggleCheckbox label="PHI protection Last name" path="share.phi_protection_last_name" />
      </div>
    </div>

    <div>
      <h3>Study Notifier</h3>

      <ToggleCheckbox label="Enabled" path="studyNotifier.notifierEnabled" />

      <IfFormValue path="studyNotifier.notifierEnabled">
        <SplitColumnsContainer>
          <TextInput label="Max age notifier (in minutes)" path="studyNotifier.maxAgeNotifier" constraint='number' mandatory/>
          <TextInput label="Max idle notifier (in minutes)" path="studyNotifier.maxIdleNotifier" constraint='number' mandatory/>
        </SplitColumnsContainer>

        <div css="position:relative;border-bottom: 1px solid rgb(210,210,210);padding-bottom:2rem;margin-bottom:1rem;">
          <FormValue path="studyNotifier.adminData">{adminData =>
            <FlipList>{
              (adminData || GET_EMPTY_NOTIFIER_ADMIN_DATA()).map((v, i) =>
                <StudyNotifierContactContainer key={v.key}>
                  <SplitColumnsContainer>
                    <TextInput label="Email" path={["studyNotifier", "adminData", i, "email"]}/>
                    <TextInput label="Full name" path={["studyNotifier", "adminData", i, "fullName"]}/>
                  </SplitColumnsContainer>
                  <Icon icon="x"
                          onClick={() => form.setValues(_.update('studyNotifier.adminData', _.filter(i => i.key !== v.key)))}/>
                </StudyNotifierContactContainer>
              )
            }</FlipList>
          }</FormValue>
          <Button small
            onClick={() => form.setValues(_.update("studyNotifier.adminData", _.concat(_, GET_EMPTY_NOTIFIER_ADMIN_DATA())))}>Add
            more</Button>
        </div>
      </IfFormValue>
    </div>

    <Billing isNew={isNew}/>

    <Errors/>
    <Button isLoading={form.isLoading} highlight css="margin-top:1rem;" type="submit">Submit</Button>
  </>}</WithForm>

}
