import {Button, Icon, Popup} from '@startlibs/components';
import {darken} from 'polished';
import {getColor, media, oneWayAnimation} from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core';
import React from 'react';
import styled, {createGlobalStyle, css} from 'styled-components';

export const CMSBackground = createGlobalStyle`
  body {
    background-color: #F3F4F8 !important;
  }
`

export const PageContainer = styled.div`
  padding: 4rem 1.5rem;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 66rem;
  min-height: 100vh;
  ${props => props.inCMS && css`
    padding-top: 2rem;
    ${media.max(520)`
      padding-top: 2rem;
    `}
  `}
  ${props => props.fullWidth && `
    max-width: 100%;
  `}
  ${props => props.appLayout && `
    padding-top: 2rem;
    padding-bottom: 2rem;
  `}
  ${props => props.isViewer && css`
    padding: 2rem 0 60px;
  `}
  ${props => props.isCCDA && css`
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 2rem;
  `}
  ${media.max(739)`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
  ${media.max(520)`
    padding-top: ${props => props.appLayout ? '1rem' : '6.25rem'};
  `}
  h3 {
    font-weight: 600;
  }
  p {
    font-size: 13px;
  }
  ${props => props.noMarginOnPrint ? css`
    @media print {
      padding: 0 !important;
    }
  `:`
    @media print {
      padding: 20mm;
    }
  `}
`

export const SectionHeading = styled.div `
  margin-bottom: 1rem;
  h3 {
    margin-bottom: 0.5rem;
  }
`

export const Card = styled.div`
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  margin-bottom: 2rem;
  & ~ ${SectionHeading} {
    margin-top: 3rem;
  }
  ${media.max(520)`
    padding: 1.5rem;
  `}
`

export const CardWithIcon = styled(Card)`
  padding-right: 13rem;
  position: relative;
`

export const RequestStatusIcon = styled(Icon)`
  display: inline-block;
  font-size: 50px;
  border-radius: 50%;
  text-align: center;
  height: 74px;
  line-height: 67px;
  width: 74px;
  border: 3px solid ${getColor('gray150')};
  color: ${getColor('gray150')};
  right: 3.5rem;
  top: 3rem;
  position: absolute;
  ${props => props.success && css`
    border-color: ${getColor('success')};
    color: ${getColor('success')};
  `}
`

export const PageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${getColor('gray210')};
  padding-top: 2rem;
  margin-top: 1rem;
  .right-wrapper {
    padding-left: 1rem;
    margin-left: auto;
  }
  ${media.max(468)`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    ${Button} {
      flex-basis: 100%;
    }
    .right-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 0.5rem;
      margin-top: -0.5rem;
      flex-direction: column-reverse;
      ${Button} {
        margin-left: 0;
        margin-top: 0.5rem;
      }
    }
    &.reviewed-footer {
      .right-wrapper {
        flex-wrap: nowrap;
        flex-basis: 100%;
        margin-left: 0;
        flex-direction: row;
        ${Button} {
          flex-grow: 1;
          margin-top: 0;
          display: inline-flex;
          padding: 0 1.25rem;
          white-space: nowrap;
          &.pdf-btn {
            min-width: 105px;
          }
          & ~ ${Button} {
            margin-left: 1rem;
          }
        }
      }
    }
  `}
  /* IE11 Adjustments */
  @media (max-width: 486px) and (-ms-high-contrast:none) {

     & { flex-direction: row; } /* IE10 */
     *::-ms-backdrop, &.reviewed-footer { flex-direction: row; } /* IE11 */

     & .right-wrapper { margin-top: .5rem; } /* IE10 */
     *::-ms-backdrop, .right-wrapper { margin-top: .5rem; } /* IE11 */

     & .right-wrapper ${Button} ~ ${Button} { margin-left: .5rem; } /* IE10 */
     *::-ms-backdrop, & & .right-wrapper ${Button} ~ ${Button} { margin-left: .5rem; } /* IE11 */

   }
`

export const HeaderButtons = styled.div `
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
  .right-wrapper {
    margin-left: 1rem;
  }
  ${media.max(468)`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .check-request, .exit-shared {
      display: none;
    }
    .right-wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      margin-left: 0;
      flex-direction: row;
      ${Button} {
        flex-grow: 1;
      }
    }
  `}
  /* IE11 Adjustments */
  @media (max-width: 486px) and (-ms-high-contrast:none) {

     & { flex-direction: row; } /* IE10 */
     *::-ms-backdrop, & { flex-direction: row; } /* IE11 */

   }
`

const fadeInUp = oneWayAnimation('transform: translateY(10px); pointer-events: none; opacity: 0;', 'transform: translateY(0); pointer-events: none; opacity: 1;')

export const HelpButton = styled(({className}) => {
    const helpBubble = usePopupToggle()

    return <div className={className} onClick={helpBubble.open}>
        <Icon icon="help"/>
        {
            helpBubble.isOpen &&
            <HelpBubble/>
        }
    </div>
})`
  position: fixed;
  z-index: 10;
  bottom: 1.25rem;
  right: 1.25rem;
  width: 50px;
  text-align: center;
  border-radius: 5rem;
  background: ${getColor('main')};
  cursor: pointer;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.25);
  :hover {
    background: ${props => darken(0.035, getColor('main')(props))};
  }
  :active {
    background: ${props => darken(0.06, getColor('main')(props))};
  }
  ${Icon} {
    font-size: 38px;
    color: white;
    line-height: 50px;
  }
  @media print {
    display: none;
  }
`

export const HelpBubble = styled(({className}) => <Popup arrow="-28" offset="0 15" className={className}
                                                         animation={fadeInUp}>
    <h4>Need help?</h4>
    <p>Contact <a className="link" href="mailto:support@purview.net" target="_blank">support@purview.net</a> or <span
        className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
</Popup>)`
  z-index: 10;
  padding: 1.5rem;
  border-radius: 10px;
  background: white;
  max-width: 22rem;
  color: rgba(0,0,0,0.5);
  h4 {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-bottom: 1rem;
  }
  p {
    font-size: 14px;
  }
  .link {
    color: ${getColor('main')};
    font-weight: 600;
  }
  ${Button} {
    width: 100%;
  }
  @media print {
    display: none;
  }
`
