import React from 'react'
import _ from 'lodash/fp'
import {TextInput, WithForm, validation, Errors} from '@startlibs/form'
import {Button} from '@startlibs/components'
import {getFetcher} from '@startlibs/utils'

const EMPTY_CUSTOMER_FORM = {
  "report_multitenant": false,
  "white_label": {
    "url": ""
  },
  "uploader": {
    "use_public_url": true
  },
  "pas": {
    "using_2fa": false,
    "confirmation_email_mandatory": true,
  },
  "share": {
    "include_study_report":2,
    "system": "viva",
    "custom_message": "",
    "send_securely": true,
    "allow_image_download": true,
    "phi_protection": true,
    "phi_protection_dob": true,
    "phi_protection_last_name": true
  },
  isNew:true
}
const toLocalErrors = ({Message,System}) => ({"":[<><b>{System}</b> config has failed.<br/> Error: <b>{Message}</b></>]})

export const PacsDBNameCheck = ({onSuccess}) => {


  return <WithForm
    action={({pacsdbname}) => getFetcher(`/api/customer/${pacsdbname}`).then(v => _.isPlainObject(v) ? v : Promise.reject([])).catch(([data,response]) => response?.status === 404 ? {pacsdbname,...EMPTY_CUSTOMER_FORM} : Promise.reject([data,response]))}
    onSuccess={(v,r) => onSuccess(r)}
    onFailure={validation.responseFailure((v) => v?.error ? toLocalErrors(v.error) : ({'':["Communication with the server has failed"]}))}
  >{form => <>
    <TextInput
      autoFocus
      label="PACS DB name"
      path="pacsdbname"
    />
    <Errors/>
    <Button type="submit" css="margin-top:1rem;" highlight isLoading={form.isLoading}>Submit</Button>
  </>}</WithForm>

}