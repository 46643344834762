import {Redirect, Router} from '@reach/router'
import {Slot, StartlibsProvider, useConstant, willUseSharedState} from '@startlibs/core'
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import {callIfFunction, customTheme} from '@startlibs/utils';
import {cold} from 'react-hot-loader'
import {hot} from 'react-hot-loader/root'
import React from 'react'

import {CheckScrollOnBottom} from './components/CheckScrollOnBottom'
import {Notifications} from './components/Notifications'
import {PageLoadingSuspense} from './components/PageLoading'
import {ProviderErrorBoundary} from './pages/errors/ProviderError'
import {ProviderTitle} from './components/ProviderTitle'
import StartlibsConfig from './UIConfig/StartlibsConfig'
import StyledTheme from './UIConfig/StyledTheme'
import {CustomerForm} from './pages/CustomerForm'


const Identity = React.forwardRef(({children}, ref) => <React.Fragment>{children}
  <div ref={ref}/>
</React.Fragment>)

const DefaultStyle = createGlobalStyle`
  ${customTheme('DefaultStyle')};
`

const App = () => {

  const params = new URLSearchParams(window.location.search)
  const jwt = useConstant(params.get("jwt"))
  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search)
  //   if (params.has("jwt")) {
  //     const t = Date.now().toString(36).toUpperCase()
  //     window.sessionStorage.setItem(t,JSON.stringify(initProps))
  //     params.delete("jwt")
  //     params.set(REFRESH_TOKEN_KEY,t)
  //     window.history.replaceState(null,"",`/cache?${params.toString()}`)
  //   }
  // },[])

  // const maxDuration = useToggle(1)
  // useEffect(() => {
  //   setTimeout(() => maxDuration.openWith(10000), 1000)
  // }, [])
  return <ThemeProvider theme={StyledTheme}>
    <StartlibsProvider value={StartlibsConfig}>
      <>
        <DefaultStyle />
        <CheckScrollOnBottom/>
        <ProviderTitle/>
        <ProviderErrorBoundary>
          <PageLoadingSuspense>
            {/*<Router component={Identity}>
              <ReportPage path="/app/:recordId" jwt={jwt}/>
              <ProviderReleaseError path="/error" />
            </Router>*/}
            <CustomerForm />
          </PageLoadingSuspense>
        </ProviderErrorBoundary>
        <Notifications/>
        <Router component={Identity}>
          <Identity path="/mobile-app/*"/>
          <Identity path="/cms-experts/*"/>
        </Router>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 100}}>
          <Slot name="Popup"/>
        </div>
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 200}}>
          <Slot name="Portal"/>
          <Slot name="Dialog"/>
          <Slot name="Confirm-Dialog"/>
          <Slot name="Notification"/>
          <Slot name="Notifications"/>
        </div>
      </>
    </StartlibsProvider>
  </ThemeProvider>
}

const ColdRedirect = cold(Redirect)

export default hot(App)
