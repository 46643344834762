import React from 'react'
import {Field, FileInput} from '@startlibs/form'
import {Button, Icon} from '@startlibs/components'
import {useToggle} from '@startlibs/core'
import {getColor} from '@startlibs/utils'
import styled, {css} from 'styled-components'
import {lighten} from 'polished'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
}


export const FileInputBoxStyle = styled.div`
  height: 9rem;
  border-radius: 5px;
  border: 1px dashed ${getColor('gray180')};
  background: ${getColor('gray240')};
  position: relative;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(0,0,0,0.6);
  user-select: none;
  :hover {
  //  background: rgb(230,230,230);
  }
  ${props => (props.draggingOver || props.uploading) && css`
    background: ${props => lighten(0.54, props.theme.colors.main)};
    border-color: ${getColor('main')};
  `}
  ${props => props.uploading && css`
    border-style: solid;
  `}
  input[type="file"] {
    z-index: 100;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::file-upload-button {
      visibility: hidden;
    }
  }
  ${Button} {
    :after {
      content: none !important;
    }
  }
`
export const LogoContainer = styled(FileInputBoxStyle)`
  position: relative;
  ${props => !props.uploading && css`
    background-color: white;
  `}
  ${props => props.image && !props.uploading && `
    background-image: url('${props.image}');
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  color: ${getColor('gray150')};
  ${Icon} {
    position: absolute;
    top: 0;
    right: .5rem;
    font-size: 3rem;
  }
  :hover {
    ${Icon} {
      color: ${getColor('gray120')};
    }
  }
`

export const checkImage = (file) => {
  if (file.size > 4194304 / 4) {
    return ['Your file needs to be smaller than 1mb.']
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
}

export const LogoUploader = ({originalPicture,form, path = "logo"}) => {

  const picture = useToggle(originalPicture)
  const draggingOver = useToggle()


  const setFile = (e) => {
    e.preventDefault()
    const files = (e.nativeEvent.dataTransfer || e.nativeEvent.target).files
    if (files.length > 0) {
      const file = files[0]
      const validation = checkImage(file)
      if (validation) {
        form.setError("image", validation)
        return
      }
    getBase64(file).then((b64image) => {
      form.setValue(path,b64image)
      const image = URL.createObjectURL(file)
      picture.openWith(image)
    })

    }
  }


  return     <Field
    label="Logo"
    descText="Upload logo image (JPEG or PNG)"
  >
    <LogoContainer
      image={picture.isOpen}
      draggingOver={draggingOver.isOpen}
    >
      {!picture.isOpen &&
      <div>
        {draggingOver.isOpen ? "Drop your image here to upload it."
          : "Click or drag and drop an image file here to upload it."}
      </div>
      }
      <FileInput onDragEnter={draggingOver.open} onDragLeave={draggingOver.close} onChange={setFile} tabIndex={-1}/>
      <Icon icon="image" />
    </LogoContainer>
  </Field>
}