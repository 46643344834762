import React from 'react'
import {useSuspense, willUseSuspense} from '../hooks/useSuspense'
import {jwtGetFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {getFetcher} from '@startlibs/utils'
import {willUseSharedState} from '@startlibs/core'

let sharedPoviderInfo = null
export let [useGlobalProviderInfo,useGlobalProviderInfoRef,setGlobalProviderInfo] = willUseSharedState()

export const useSharedProviderInfoSuspense = willUseSuspense(() => {
  if (sharedPoviderInfo) {
    return Promise.resolve(sharedPoviderInfo)
  } else {
    return getFetcher("/api/providerInfo")
      .then((providerInfo) => {
        setGlobalProviderInfo(providerInfo);
        return providerInfo
      })
  }
})

export const WithProviderInfo = ({children}) => {
  const providerInfo = sharedPoviderInfo = useSuspense("/api/providerInfo")
  setGlobalProviderInfo(providerInfo)
  return children(providerInfo)
}

const useAuthSuspense = willUseSuspense((url) => jwtGetFetcher(getJwt())(url))
export const WithUserInfo = ({children}) => {
  const userInfo = useAuthSuspense("/api/userInfo")
  return children(userInfo)
}