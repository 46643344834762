import _ from 'lodash/fp'
import {formatDate, parseDate} from '@startlibs/utils'

export function safeMultipleNewLineToBr(str) {
  return (str||"").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/\n/g,'<br/>')
}

export const EMPTY_CASE_INFO = {
  patientDiagnosis: "",
  clinicalSummary: "",
  questionsConsultant: [{id: Date.now() + "", answer: '', question: ''}],
  version: 3
}


export const tryOrFail = (test,fail) => {
  try {
    return test()
  } catch(e) {
    fail()
  }
}

export const toLocalState = caseRequest => _.flow(
  _.update('caseInfo', (c) => c ? _.set('version', 3, c) : EMPTY_CASE_INFO),
  _.set(['patientInfo', 'confirmEmail'], _.get(['patientInfo', 'email'], caseRequest))
)(caseRequest)


export const filterExpert = (query) => expert => {
  const tokens = [
    (expert.firstName|| "").toLowerCase().trim(),
    (expert.lastName || "").toLowerCase().trim(),
    (expert.expertEmail || "").toLowerCase(),
    ..._.flatten((expert.specialization||[]).map(v => v.toLowerCase().split(" ")))
  ]
  const queryTokens = query.toLowerCase().trim().split(" ")
  return queryTokens.filter(q => tokens.find(t => t.indexOf(q.trim()) >= 0)).length === queryTokens.length
}


export const priceConstraint = (v) => {
  const price = v.replace(/^[^0-9]*([0-9]*\.?)[^0-9]*([0-9]{0,2})([0-9]?)(.*)/,"$1$2|$3")
  const [current,extra] = price.split("|")
  if (extra) {
    return current.slice(0,-3) + current.slice(-2,-1) + "." + current.slice(-1) + extra
  }
  return current
}
export const formatPrice = (v) => v ? Number(v).toFixed(2) : v

export const searchToParams = (search) => {
  const urlParams = new URLSearchParams(search).entries()
  const result = {}
  for (var pair of urlParams) {
    result[pair[0]] = pair[1]
  }
  return {
    studyStatus: '',
    ...result
  }
}
export const getDateValue = (key,params) => params[key] ? parseDate(params[key],new Date(),"MM-dd-yyyy") : NaN

export const getDate = (n) => {
  const date = new Date()
  date.setDate(date.getDate() + n)
  return date
}

export const getDateYear = (n) => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + n)
  return date
}

export const formatDateNoUTC = (date,format,messages) => formatDate(date,format,messages,true)

export const createDateReformatter = (from, to) => (date) => formatDate(parseDate(date,new Date(),from),to)

export function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
