import React from 'react'
import styled from 'styled-components'
import {callIfFunction, getColor} from '@startlibs/utils'
import {Button, Dialog} from '@startlibs/components'

const ErrorPageLayout = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(0,0,0,0.5);
  padding: 1rem;
  @supports (flex-wrap: wrap) {
    display: flex;
    align-items: center;
  }
  a {
    color: ${getColor('main')};
    font-weight: 600;
  }
`
const ErrorCard = styled.div`
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 40rem;
  margin: 0 auto;
  p {
    font-size: 14px;
  }
`
const CardHeader = styled.div`
  margin-bottom: 1.5rem;
  h1 {
    color: ${getColor('main')};
    margin-bottom: 0.5rem;
    font-size: 22px;
  }
  p {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
  }
`

export class ProviderErrorBoundary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    const errorData = error && callIfFunction(error.get)
    if (errorData && errorData.ignoreError) {
      return {withoutUX:true}
    }
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  closeDialog = () => {
    this.setState({closingDialog: true})
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }

  render() {
    if (this.state.withoutUX) {
      return null
    }
    if (this.state.hasError) {
      return <ErrorPageLayout withoutLogo>
        <ErrorCard>
          <CardHeader>
            <h1>Something went wrong</h1>
          </CardHeader>
          <p><b>Please reload the page and try again.</b></p>
          <p>If the error persists please contact <a href="mailto:support@purview.net"
                                                     target="_blank">support@purview.net</a> or <span
            className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
        </ErrorCard>
      </ErrorPageLayout>
    }

    return this.props.children
  }
}

export class DialogErrorBoundary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  closeDialog = () => {
    this.setState({closingDialog: true})
    setTimeout(() => window.parent.postMessage(JSON.stringify({type: 'removeIframe'}), '*'), 300)
  }

  render() {
    if (this.state.hasError) {
      return <Dialog
        closeDialog={this.props.closeDialog}
        footer={<Button onClick={this.props.closeDialog}>Close</Button>}
      >
        <ErrorPageLayout withoutLogo>
          <ErrorCard>
            <CardHeader>
              <h1>Something went wrong</h1>
            </CardHeader>
            <p><b>Please reload the page and try again.</b></p>
            <p>If the error persists please contact <a href="mailto:support@purview.net"
                                                       target="_blank">support@purview.net</a> or <span
              className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
          </ErrorCard>
        </ErrorPageLayout>
      </Dialog>
    }

    return this.props.children
  }
}