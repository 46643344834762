import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {FormValue, TextInput, DatePicker, Combobox, IfFormValue, ToggleCheckbox} from "@startlibs/form";
import {SplitColumnsContainer} from "@startlibs/components";

styled

const PERIOD_TYPES_LABELS = {
  DAY_1: 'Monthly, charges 1st day of month',
  DAY_15: 'Monthly, charges 15th day of month',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly'
}
const PERIOD_TYPES = _.keys(PERIOD_TYPES_LABELS)

const BILLING_TYPES_LABELS = {
  STUDY_COUNT: 'Study count',
  LIMIT_HIT: 'Limit hit',
  AMOUNT_LIMIT_HIT: 'Amount limit hit'
}
const BILLING_TYPES = _.keys(BILLING_TYPES_LABELS)

export const formatPrice = (v) => {
  const allowedChars = v.replace(/[^\d.]/g,"")
  const dotIndex = allowedChars.indexOf('.')
  if (dotIndex >= 0 && dotIndex < allowedChars.length - 3)
    return addCommas(allowedChars.replace(/\./g,'').replace(/(\d+)(\d\d)/,"$1.$2"))
  return addCommas(allowedChars.replace(/^([^.]*\.\d{0,2}).*/,"$1"))
}
const addCommas = (text) => {
  const [int,rest] = text.split(".")
  return _.flow(
    _.split(""),
    _.reverse,
    _.chunk(3),
    _.map(_.flow(_.reverse,_.join(""))),
    _.reverse,
    _.join(","),
    v => [v,...(_.isString(rest) ? [rest] : [])].join(".")
  )(int)
}

export const Billing = ({isNew}) => {
  return <div>
    <h3>Billing</h3>

    <ToggleCheckbox label="Enabled" path="billing.enabled" />

    <IfFormValue path="billing.enabled">
      <SplitColumnsContainer>
        <Combobox
          label="Period Type" path="billing.periodType" mandatory options={PERIOD_TYPES}
          getLabel={(v) => PERIOD_TYPES_LABELS[v]}
        />
        <Combobox
          label="Billing Type" path="billing.billingType" mandatory options={BILLING_TYPES}
          getLabel={(v) => BILLING_TYPES_LABELS[v]}
        />
      </SplitColumnsContainer>

      <SplitColumnsContainer>
        <TextInput path="billing.unitPrice" label="Unit price" mandatory constraint={formatPrice} />
        <TextInput path="billing.customerNumber" label="Customer Number" mandatory />
      </SplitColumnsContainer>

      {/*<ToggleCheckbox path="billing.continuousBilling" label="Continuous Billing" />*/}

      <IfFormValue path="billing.billingType" check={billingType => billingType && billingType !== 'STUDY_COUNT'}>
        <TextInput path="billing.limit" label="Limit" constraint="number" mandatory />
      </IfFormValue>
        {/*<TextInput path="billing.duration" label="Duration" constraint="number" />*/}

      <IfFormValue path="billing.periodType" check={periodType => periodType === 'YEARLY'}>
        <DatePicker
          weekStartsMonday
          label="Contract start date"
          placeholder="Ex: 01/25/2023"
          format="MM/dd/yyyy"
          path="billing.startDate"
          mandatory
        />
      </IfFormValue>
    </IfFormValue>
  </div>
}