import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {Fill, useToggle, willUseSharedState} from '@startlibs/core'
import {Icon} from '@startlibs/components'
import {callIfFunction, getColor, oneWayAnimation} from '@startlibs/utils'
import {useFlipAnimation} from '@startlibs/components'

const NotificationContainer = styled.div `
  position: fixed;
  top: 0;
  height: 1px;
  left: 0;
  right: 0;
  text-align: center;
`

const Notification = styled.div `
  padding: 1rem 3.5rem 1rem 1rem;
  border-radius: 5px;
  color: white;
  text-align: left;
  line-height: 20px;
  background: ${props => getColor(props.type)(props)};
  box-shadow: 0 2px 0px 0px rgba(0,0,0,0.25);
  min-width: 12rem;
  min-height: 44px;
  display: inline-block;
  position: relative;
  top: 1rem;
  ${Icon} {
    color: white;
    display: inline-block;
    background-color: rgba(0,0,0,0.2);
    font-size: 14px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    &:hover {
      background-color: rgba(0,0,0,0.3);
    }
  }
`

export const [useNotification, useNotificationRef,setNotification] = willUseSharedState()
export const willSetNotification = (str) => () => setNotification(str)

const animation = oneWayAnimation('opacity:0; transform:translateY(-20px)', 'opacity:1;transform:translate(0)')

export const Notifications = () => {
  const toggle = useToggle()
  const [rawNotification,setNotification] = useNotification()

  const {msg,type} = _.isObject(rawNotification) ? rawNotification : {type:"success",msg:rawNotification}

  useEffect(() => {
    if (msg) {
      toggle.open()
      setTimeout(toggle.willCloseAfter(5000), 100)
    }
  },[msg])
  useEffect(() => {
    if (!toggle.isOpen) {
      setNotification(null)
    }
  },[toggle.isOpen])

  return toggle.isOpen && (<Fill name="Notifications">
    <FlipNotification type={type}>
      {callIfFunction(msg,toggle.close)}
      <Icon icon="x" onClick={toggle.close}/>
    </FlipNotification>
  </Fill>)
}
const FlipNotification = (props) => {
  const ref = useRef()
  useFlipAnimation(ref,animation)
  return <NotificationContainer ref={ref}><Notification id="js-share-notification" {...props}/></NotificationContainer>
}