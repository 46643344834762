import {useToggle} from '@startlibs/core'
import React from 'react'

import {AllocationSuccess} from './allocation/AllocationSuccess'
import { Card, PageContainer } from '../components/PageLayout';
import {CustomerInfoForm} from './allocation/CustomerInfoForm'
import {PacsDBNameCheck} from './allocation/PacsDBNameCheck'
import {lazyApplicationContext} from '../config/lazyApplicationContext'

export const CustomerForm = () => {

  const applicationContext = lazyApplicationContext.read()

  const allocationInfo = useToggle()
  const success = useToggle()

  return <PageContainer>
    <Card>
    <h1>{applicationContext.application}</h1>
    {
      success.isOpen
      ? <AllocationSuccess values={success.isOpen} goBack={() => {allocationInfo.close();success.close()}} />
      : allocationInfo.isOpen
      ? <CustomerInfoForm
          onSuccess={success.openWith}
          goBack={() => {allocationInfo.close();success.close()}}
          values={allocationInfo.isOpen}
        />
      : <PacsDBNameCheck
          onSuccess={allocationInfo.openWith}
        />
    }
    </Card>
  </PageContainer>
}